<template>
    <div class="task-detail-content">
        <el-scrollbar :native="false" class="task-detail">
            <div class="task-content" v-if="task_type === 1 || task_type === 2">
                <div class="task-title">
                    <div style="display: flex;">
                        <span class="text">{{taskDetailData.task_title}}</span>
                        <span class="bounty" v-if="taskDetailData.task_money">￥{{taskDetailData.task_money}}</span>
                    </div>
                    <div class="btn-grablue-32 account-btn" @click="goBack">返回</div>
                </div>
                <div class="task-info">
                    <ul>
                        <li>
                            <span>发布日期：</span>
                            <span>{{taskDetailData.create_time}}</span>
                        </li>
                        <li>
                            <span>剩余日期：</span>
                            <span style="color: #FF0000;">{{theLastTime}}</span>
                        </li>
                        <li>
                            <span>需求类型：</span>
                            <span v-if="trainDetail.task_type_name">{{trainDetail.task_type_name}}</span>
                            <span v-if="taskDetailData.title">{{taskDetailData.title}}</span>
                        </li>
                        <li>
                            <span>参与人数：</span>
                            <span>{{taskDetailData.join_num}}</span>
                        </li>
                    </ul>
                    <div class="task-status" v-if="taskDetailData.task_is_apply === 3">
                        <div v-if="isApply" class="btn-grablue-32 new-account-btn" @click="applyEnroll(1)">同意合作</div>
                        <div v-if="isApply" class="btn-grablue-32 new-disAccount-btn" @click="applyEnroll(2)">拒绝合作</div>
                        <div v-if="!isApply && apply_status == 1" class="btn-grablue-32 new-account-btn">已同意</div>
                        <div v-if="!isApply && apply_status == 2" class="time-out">已拒绝</div>
                    </div>
                    <div class="task-status" v-else>
                        <img v-if="taskDetailData.state === '无需报名'" src="../../../assets/img/image/no-apply.png" alt="">
                        <img v-if="taskDetailData.state === '已报名'" src="../../../assets/img/image/applyed.png" alt="">
                        <span v-if="taskDetailData.task_end_time === 0 || taskDetailData.state === '已截止' || taskDetailData.state === '已截至'" class="time-out">已截止</span>
                        <span v-if="taskDetailData.task_end_time > 0" class="time-ing">进行中</span>
                        <!--<span v-else class="time-ing">进行中</span>-->
                        <div v-if="taskDetailData.state === '报名'" class="btn-grablue-32 account-btn" @click="goToApply">报名</div>
                    </div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">需求描述</span>
                    </div>
                    <div class="require-content" v-html="taskDetailData.task_content"></div>
                </div>
                <div class="task-require" v-if="taskDetailData.cooperation_content">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">合作咨询</span>
                    </div>
                    <div class="require-content" v-html="taskDetailData.cooperation_content"></div>
                </div>
                <div class="task-goods" v-if="taskDetailData.goods_url">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">推广商品</span>
                    </div>
                    <div class="goods-content">
                        <div class="goods-cover">
                            <img :src="taskDetailData.goods_cover" alt="">
                        </div>
                        <div class="goods-info">
                            <span class="goods-title">{{taskDetailData.goods_name}}</span>
                            <span class="price">￥{{taskDetailData.goods_price}}</span>
                        </div>
                    </div>
                </div>
                <div class="task-annex" v-if="taskDetailData.task_file.length > 0">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">附件</span>
                    </div>
                    <ul class="annex-content">
                        <li v-for="item in taskDetailData.task_file">
                            <span class="file-name" @click="downLoadAnnex(item)">{{item.file_name}}</span>
                            <div class="down-progress">
                                <div class="download" @click="downLoadAnnex(item)">
                                    <i class="iconfont">&#xe607;</i>
                                    下载
                                </div>
                                <!--<div class="file-down-progress">-->
                                    <!--<el-progress :percentage="50"></el-progress>-->
                                    <!--<i class="iconfont">&#xe623;</i>-->
                                <!--</div>-->
                            </div>
                        </li>
                        <!--<li>-->
                        <!--<span class="file-name" title="项目.word">项目.word</span>-->
                        <!--<div class="down-progress">-->
                        <!--<div class="download">-->
                        <!--<i class="iconfont">&#xe607;</i>-->
                        <!--下载-->
                        <!--</div>-->
                        <!--<div class="file-down-progress">-->
                        <!--<el-progress :percentage="100"></el-progress>-->
                        <!--<span class="finish-down">下载完成</span>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</li>-->
                    </ul>
                </div>
            </div>
            <div class="task-content" v-if="task_type === 4">
                <div class="task-title">
                    <span class="text">{{scriptDetail.task_title}}</span>
                </div>
                <div class="task-info">
                    <ul>
                        <li>
                            <span>发布日期：</span>
                            <span>{{taskDetailData.create_time}}</span>
                        </li>
                        <li>
                            <span>剩余日期：</span>
                            <span style="color: #FF0000;">{{theLastTime}}</span>
                        </li>
                        <li>
                            <span>指定班级：</span>
                            <span v-for="classItem in scriptDetail.class_info">{{classItem.name}}</span>
                        </li>
                    </ul>
                    <div class="task-status">
                        <span v-if="taskDetailData.state === '已截止' || taskDetailData.state === '已截至' || taskDetailData.task_end_time === 0" class="time-out">已截止</span>
                        <span v-else class="time-ing">进行中</span>
                    </div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">脚本简介</span>
                    </div>
                    <div class="require-content" v-html="scriptDetail.task_content"></div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">脚本内容</span>
                    </div>
                    <div class="require-content" v-html="scriptDetail.script.script_content"></div>
                    <div class="script-table" v-if="scriptDetail.script.camera_lens && scriptDetail.script.camera_lens.length">
                        <div class="script-table-row">
                            <div class="script-table-column">镜头</div>
                            <div class="script-table-column">景别</div>
                            <div class="script-table-column">拍摄方向</div>
                            <div class="script-table-column">运镜</div>
                            <div class="script-table-column">拍摄内容</div>
                            <div class="script-table-column">音乐/字幕</div>
                            <div class="script-table-column">时间</div>
                        </div>
                        <div class="script-table-row" v-for="item in scriptDetail.script.camera_lens">
                            <div class="script-table-column"><span class="text-overflow">{{item.number}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.farewell}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.direction}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.mirror_movement}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.content}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.music_or_subtitle}}</span></div>
                            <div class="script-table-column"><span class="text-overflow">{{item.time}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="task-content" v-if="task_type === 3">
                <div class="task-title">
                    <span class="text">{{taskDetailData.task_title}}</span>
                    <span class="bounty" v-if="taskDetailData.task_money">￥{{taskDetailData.task_money}}</span>
                </div>
                <div class="task-info">
                    <ul>
                        <li>
                            <span>发布日期：</span>
                            <span>{{taskDetailData.create_time}}</span>
                        </li>
                        <li>
                            <span>剩余日期：</span>
                            <span style="color: #FF0000;">{{theLastTime}}</span>
                        </li>
                        <li>
                            <span>需求类型：</span>
                            <span>{{trainDetail.task_type_name}}</span>
                        </li>
                        <li>
                            <span>制定班级：</span>
                            <span v-for="classItem in scriptDetail.class_info">{{classItem.name}}</span>
                        </li>
                    </ul>
                    <div class="task-status">
                        <span v-if="taskDetailData.state === '已截止' || taskDetailData.state === '已截至' || taskDetailData.task_end_time === 0" class="time-out">已截止</span>
                        <span v-else class="time-ing">进行中</span>
                    </div>
                </div>
                <div class="task-require" v-if="trainDetail.train_introduction.name">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetail.train_introduction.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetail.train_introduction.content"></div>
                </div>
                <div class="task-require" v-if="trainDetail.train_target.name">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetail.train_target.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetail.train_target.content"></div>
                </div>
                <div class="task-require" v-if="trainDetail.train_requirement.name">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetail.train_requirement.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetail.train_requirement.content"></div>
                </div>
                <div class="task-require" v-if="trainDetail.train_content.name">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetail.train_content.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetail.train_content.content"></div>
                </div>
                <div class="task-annex" v-if="trainDetail.script_id > 0">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">关联脚本</span>
                    </div>
                    <div class="annex-content">
                        <div v-if="trainDetail.scriptDetail" @click="viewScriptDetail" class="script-name">
                            {{trainDetail.scriptDetail.name}}
                        </div>
                    </div>
                </div>
                <div class="task-annex" v-if="trainDetail.source_material.length > 0">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">素材下载</span>
                    </div>
                    <ul class="annex-content">
                        <li v-for="item in trainDetail.source_material">
                            <span class="file-name">
                                <i class="iconfont">&#xe66d;</i>
                                {{item.name}}
                            </span>
                            <div class="down-progress">
                                <div class="download" @click="downLoadTrainAnnex(item)">
                                    <span class="download-bg">
                                        <i class="iconfont">&#xe607;</i>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="train-evaluete">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">实训评价</span>
                    </div>
                    <el-table :data="trainDetail.train_evaluete" border style="width: 100%; flex: 1"
                              size="medium"
                              :header-cell-style="{fontWeight: 'normal', color: '#222', background: '#ECEBFF',height:'50px'}"
                              :cell-style="{fontSize: '14px',color: '#222'}">
                        <el-table-column prop="number" label="序号" align="center"></el-table-column>
                        <el-table-column prop="content" label="内容" align="center"></el-table-column>
                        <el-table-column prop="requirement" label="要求" align="center"></el-table-column>
                        <el-table-column prop="score" label="分值" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.score}}分</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="task-submit" v-if="taskDetailData.task_end_time !== 0 && this.task_enroll.length === 0 && taskDetailData.task_type !== 2">
                <div class="title">
                    <span class="left-icon"></span>
                    <span class="text">我要交任务</span>
                </div>
                <el-input
                        resize="none"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        v-model="submitTextarea">
                </el-input>
                <el-button class="upload-btn" size="medium" @click="uploadAnnex">
                    <span>上传附件</span>
                </el-button>
                <ul class="file-list" v-if="isReloadData">
                    <li v-for="(item, index) in scriptFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delScriptFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in graphicFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delGraphicFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in videoFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delVideoFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in dataAnalysisFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delDataAnalysisFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in fileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delFiles(item, index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in urlFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delUrlFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                </ul>
                <div class="btn-grablue-32 account-btn" @click="taskSubmit">提交</div>
            </div>
            <div class="task-submit" v-else-if="taskDetailData.task_end_time !== 0 && this.task_enroll.length > 0 && this.isEnroll === 2 && taskDetailData.task_type === 2">
                <div class="title">
                    <span class="left-icon"></span>
                    <span class="text">我要交任务</span>
                </div>
                <el-input
                        resize="none"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        v-model="submitTextarea">
                </el-input>
                <el-button class="upload-btn" size="medium" @click="uploadAnnex">
                    <span>上传附件</span>
                </el-button>
                <ul class="file-list" v-if="isReloadData">
                    <li v-for="(item, index) in scriptFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delScriptFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in graphicFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delGraphicFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in videoFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delVideoFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in dataAnalysisFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delScriptFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in fileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delFiles(item, index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in urlFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delUrlFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                </ul>
                <div class="btn-grablue-32 account-btn" @click="taskSubmit">提交</div>
            </div>
            <div class="task-submit" v-else-if="taskDetailData.task_end_time !== 0 && this.task_enroll.length > 0 && this.isEnroll === 2 && taskDetailData.task_type === 2 && applyStatus === 1">
                <div class="title">
                    <span class="left-icon"></span>
                    <span class="text">我要交任务</span>
                </div>
                <el-input
                        resize="none"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        v-model="submitTextarea">
                </el-input>
                <el-button class="upload-btn" size="medium" @click="uploadAnnex">
                    <span>上传附件</span>
                </el-button>
                <ul class="file-list" v-if="isReloadData">
                    <li v-for="(item, index) in scriptFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delScriptFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in graphicFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delGraphicFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in videoFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delVideoFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in dataAnalysisFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delScriptFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in fileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delFiles(item, index)">&#xe623;</i>
                        </div>
                    </li>
                    <li v-for="(item, index) in urlFileList">
                        <div class="file-type">
                            <i class="iconfont">&#xe791;</i>
                            <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                        </div>
                        <div class="file-text">
                            <i class="iconfont" @click="delUrlFiles(index)">&#xe623;</i>
                        </div>
                    </li>
                </ul>
                <div class="btn-grablue-32 account-btn" @click="taskSubmit">提交</div>
            </div>
            <div class="task-submit-list" v-if="taskDetailData.task_end_time === 0">
                <div class="submissions">
                    <div class="submissions-title">
                        提交学生
                    </div>
                    <div class="class-select">
                        <span>班级筛选</span>
                        <el-select class="el-select" v-model="selectClassValue" placeholder="请选择" @change="classSelect">
                            <el-option
                                    v-for="(item,index) in executeClass"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <el-table class="tableList" :data="selectDetailList" style="width: 100%; flex: 1; " size="medium" :header-cell-style="{fontWeight: 'normal', color: '#666666', background: '#F5F5F5',fontSize: '14px', height: '46px'}" :cell-style="{fontSize: '14px',color: '#666666', height:'70px'}">
                        <el-table-column type="index" label="序号" align="center" width="120"></el-table-column>
                        <el-table-column prop="username" label="姓名" align="center"></el-table-column>
                        <el-table-column prop="name" label="班级" align="center"></el-table-column>
                        <el-table-column prop="create_time" label="教师评语" align="center"></el-table-column>
                        <el-table-column prop="" label="服务打分" align="center">
                            <template slot-scope="scope">
                                <el-rate disabled v-if="scope.row.teacher_comment" v-model="scope.row.teacher_comment.score"></el-rate>
                                <span v-else>暂未评分</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="评审结果" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.teacher_comment">
                                    <i v-if="scope.row.teacher_comment.is_qualified === 1" class="iconfont is-qualified">&#xe692;</i>
                                    <i v-if="scope.row.teacher_comment.is_qualified === 2" class="iconfont no-qualified">&#xe690;</i>
                                </div>
                                <span v-else>暂无审批</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <span @click="viewAnnex(scope.row)" style="color: #4D5AFF;cursor: pointer;">查看详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            style="padding: 30px 0"
                            class="pages-center"
                            :current-page="studentPages.currentPageNum"
                            :page-size="studentPages.eachPageNum"
                            layout="prev, pager, next, jumper"
                            :total="studentPages.total"
                            @current-change="studentCurrentChange">
                    </el-pagination>
                </div>
            </div>
        </el-scrollbar>
        <!--企业信息-->
        <div v-if="taskDetailData.task_money" class="company-info">
            <div class="company-header" @click="viewCompanyDetail(companyInfo.user_id)">
                <div class="company-cover">
                    <img :src="companyInfo.logo" alt="">
                </div>
                <span class="company-name">{{companyInfo.name}}</span>
            </div>
            <div class="company-detail">
                <div class="detail-item">
                    <span class="title">企业简介：</span>
                    <span class="text">{{companyInfo.intro}}</span>
                </div>
                <div class="detail-item" v-if="companyInfo.business_scope">
                    <span class="title">营业范围：</span>
                    <span class="text">{{companyInfo.business_scope}}</span>
                </div>
                <div class="detail-item">
                    <span class="title">公司地址：</span>
                    <span class="text">{{companyInfo.company_address}}</span>
                </div>
                <div class="detail-item">
                    <span class="title">联系电话：</span>
                    <span class="text">{{companyInfo.phone}}</span>
                </div>
                <div class="detail-item">
                    <span class="title">邮箱地址：</span>
                    <span class="text">{{companyInfo.email}}</span>
                </div>
                <div class="detail-item" v-if="companyInfo.registration_date">
                    <span class="title">注册时间：</span>
                    <span class="text">{{companyInfo.registration_date}}</span>
                </div>
                <div class="detail-item" v-if="companyInfo.shop_name">
                    <span class="title">店铺名称：</span>
                    <span class="text">{{companyInfo.shop_name}}</span>
                </div>
                <div class="detail-item" v-if="companyInfo.shop_url">
                    <span class="title">店铺网址：</span>
                    <a :href="companyInfo.shop_url" class="text" target="_blank">{{companyInfo.shop_url}}</a>
                </div>
                <div class="detail-item">
                    <span class="title" style="line-height: 30px;">企业资质：</span>
                    <el-button type="primary" class="determine-btn" @click="viewEnterpriseQualification">点击查看</el-button>
                </div>
            </div>
            <div class="task-goods">
                <div class="publish-num">
                    <span>任务数</span>
                    <span class="num">{{companyInfo.taskCount}}</span>
                </div>
                <div class="publish-num">
                    <span>商品数</span>
                    <span  class="num">{{companyInfo.goodsCount}}</span>
                </div>
            </div>

        </div>
        <!--报名弹窗-->
        <el-dialog
                title="报名"
                :visible.sync="applyDialog"
                width="500px"
                class="apply-dialog">
            <div>
                <span>平台选择</span>
                <el-select v-model="platformValue" @change="selectPlatform">
                    <el-option
                            v-for="item in platformList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="account-list">
                <el-radio v-model="accountRadio" :label="item.id" v-for="item in accountList">
                    <img class="avatar-img" :src="item.avatar" alt="">
                    <span>{{item.nickname}}</span>
                </el-radio>
                <span class="no-data" v-if="accountList.length === 0">暂无数据</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="applyDialog = false">取 消</el-button>
                <el-button type="primary" @click="applyClick">确 定</el-button>
            </span>
        </el-dialog>
        <!--上传附件弹窗-->
        <el-dialog
                title="上传附件"
                :visible.sync="uploadAnnexDialog"
                width="720px"
                class="annex-dialog">
            <div class="annex-list">
                <el-tabs v-model="annexTab" @tab-click="changeAnnexTab" class="annex-tab">
                    <el-tab-pane label="脚本库" name="scriptLibrary">
                        <el-table :data="scriptList" ref="multipleScript"
                                  style="width: 100%; margin: 10px 0;" height="1%" size="medium" class="attention-table"
                                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#5F5C74', background: '#EFEEFF'}"
                                  :cell-style="{fontSize: '14px',color: '#16113F'}" @selection-change="chooseScript">
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column prop="name" align="left" label="脚本名称" :resizable="false"></el-table-column>
                            <el-table-column prop="classification_name" align="center" label="分类" :resizable="false" width="120"></el-table-column>
                            <el-table-column prop="create" align="center" label="发布时间" :resizable="false" width="200"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="图文库" name="graphicLibrary">
                        <el-select v-model="graphicAccountValue" placeholder="请选择" class="platform-select" @change="toggleGraphicAccount">
                            <el-option
                                    v-for="item in graphicAccountList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                        <el-table :data="graphicList" ref="multipleGraphic"
                                  style="width: 100%; flex: 1; margin-top: 10px" height="1%" class="attention-table"
                                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#5F5C74', background: '#EFEEFF'}"
                                  :cell-style="{fontSize: '14px',color: '#16113F'}" @selection-change="chooseGraphic">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="图文名称" align="left">
                                <template slot-scope="scope">
                                    <div class="text-overflow" :title="scope.row.title">{{scope.row.title}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="updated_at" label="发布时间" align="center" width="200"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="剪辑库" name="clipLibrary">
                        <el-scrollbar class="clip-library" :native="false">
                            <div class="item-list">
                                    <el-checkbox-group v-model="checkVideoList" class="clip-checkbox" @change="chooseUploadVideo">
                                        <div class="item" v-for="selectItem in videoLists">
                                            <el-checkbox :label="selectItem.id"
                                                         class="img-item"
                                                         :key="selectItem.id">
                                                <video :src="selectItem.video_file.src" controls="controls" width="150" height="96"></video>
                                                <span class="video-name">{{selectItem.title}}</span>
                                            </el-checkbox>
                                        </div>
                                    </el-checkbox-group>
                                <div v-if="videoLists.length === 0" class="video-no-data">暂无数据</div>
                            </div>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="数据分析" name="dataAnalysisLibrary">
                        <el-table :data="dataAnalysisList" ref="multipleDataAnalysis"
                                  style="width: 100%; margin: 10px 0;" height="1%" size="medium" class="attention-table"
                                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#5F5C74', background: '#EFEEFF'}"
                                  :cell-style="{fontSize: '14px',color: '#16113F'}" @selection-change="chooseDataAnalysis">
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column prop="title" align="left" label="数据标题" :resizable="false"></el-table-column>
                            <el-table-column prop="create_time" align="center" label="发布时间" :resizable="false" width="200"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="本地上传" name="localUpload">
                        <el-button class="upload-btn" size="medium">
                            <span>上传附件</span>
                            <input type="file" :accept="acceptFormat" title="" multiple @change="chooseFile($event)" name='file'>
                        </el-button>
                        <el-scrollbar class="upload-file-list" v-if="fileList.length !== 0" :native="false">
                            <div v-for="(item, index) in fileList" class="file-item">
                                <div class="file-type">
                                    <i class="iconfont">&#xe791;</i>
                                    <span class="file-name" :title="item.file_name">{{item.file_name}}</span>
                                </div>
                                <div class="file-text">
                                    <span>上传成功</span>
                                    <i class="iconfont" @click="delFiles(item, index)">&#xe8e9;</i>
                                </div>
                            </div>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="链接提交" name="linkSubmit">
                        <div class="link-list">
                            <div class="link-item">
                                <span class="link-text">链接1</span>
                                <el-input v-model="linkInput1" placeholder="请输入正确链接"></el-input>
                            </div>
                            <div class="link-item">
                                <span class="link-text">链接2</span>
                                <el-input v-model="linkInput2" placeholder="请输入正确链接"></el-input>
                            </div>
                            <div class="link-item">
                                <span class="link-text">链接3</span>
                                <el-input v-model="linkInput3" placeholder="请输入正确链接"></el-input>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="uploadAnnexDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveAnnex"  >确 定</el-button>
                <!--<el-button type="info" v-if="selectRowList.length === 0 || checkVideoList.length === 0" disabled>确 定</el-button>-->
            </span>
        </el-dialog>
        <!--查看资质图片弹窗-->
        <el-dialog
                title="查看资质"
                :visible.sync="enterpriseQualificationDialog"
                width="945px"
                class="img-dialog">
            <div class="img-content">
                <div class="big-img">
                    <div class="show-img">
                        <img :src="big_img" alt="">
                    </div>
                </div>
                <div class="small-img">
                    <el-scrollbar :native="false">
                        <div  v-for="item in companyInfo.enterprise_qualification" class="img-div">
                            <img :src="item.src" alt="" @click="viewBigImg(item.src)">
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </el-dialog>
        <!--脚本详情弹窗-->
        <el-dialog title="查看脚本" :visible.sync="dialogViewScript" width="66%" class="annex-list-dialog" :close-on-click-modal="false">
            <div class="view-script-main">
                <div class="view-script-item-top">
                    <div class="view-script-img">
                        <img :src="trainDetail.scriptDetail.cover_url" alt="">
                    </div>
                    <div class="view-script-right">
                        <div class="view-script-name"><span>脚本名称：{{trainDetail.scriptDetail.name}}</span></div>
                        <div class="view-script-fenlei"><span>脚本分类：{{trainDetail.scriptDetail.classification_name}}</span></div>
                        <div class="view-script-jianjie" v-if="trainDetail.scriptDetail.script_introduction"><span>脚本简介：{{trainDetail.scriptDetail.script_introduction}}</span></div>
                    </div>
                </div>
                <div class="view-script-body" v-if="trainDetail.scriptDetail.script_content !=='<p><br></p>'">
                    <div class="view-script-body-title">脚本内容</div>
                    <div class="view-script-body-content" v-html="trainDetail.scriptDetail.script_content"></div>
                </div>
                <el-scrollbar class="script-dialog-table">
                    <div class="dialog-table-row">
                        <div class="dialog-table-column">镜头</div>
                        <div class="dialog-table-column">景别</div>
                        <div class="dialog-table-column">拍摄方向</div>
                        <div class="dialog-table-column">运镜</div>
                        <div class="dialog-table-column">拍摄内容</div>
                        <div class="dialog-table-column">音乐/字幕</div>
                        <div class="dialog-table-column">时间</div>
                    </div>
                    <div class="dialog-table-row" v-for="item in trainDetail.scriptDetail.camera_lens">
                        <div class="dialog-table-column"><span class="text-overflow">{{item.number}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.farewell}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.direction}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.mirror_movement}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.content}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.music_or_subtitle}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.time}}</span></div>
                    </div>
                </el-scrollbar>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" class="determine-btn" @click="dialogViewScript = false">确 定</el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions,mapMutations} from 'vuex'

    export default {
        name: "TaskDetail",
        data() {
            return {
                isApply:true,
                apply_status:1,//1是同意，2是不同意
                applyStatus: null,//1是同意，2是不同意
                //报名弹窗
                applyDialog: false,
                //任务类型
                task_type: null,
                //普通任务详情
                taskDetailData: {
                    task_file: [],
                    task_library: [],
                    works_file: []
                },
                //脚本任务详情
                scriptDetail: {
                    task_title: '',
                    create_time: '',
                    class_info: [],
                    task_content: '',
                    script: {
                        script_content: '',
                        script_introduction: '',
                        camera_lens: []
                    }
                },
                //实训任务详情
                trainDetail: {
                    task_type_name: '',
                    train_introduction: {
                        name: '',
                        content: ''
                    },
                    train_target: {
                        name: '',
                        content: ''
                    },
                    train_requirement: {
                        name: '',
                        content: '',
                    },
                    train_determined: [],
                    train_determined_one: {
                        name: '',
                        content: ''
                    },
                    train_determined_two: {
                        name: '',
                        content: ''
                    },
                    train_content: {
                        name: '',
                        content: ''
                    },
                    train_evaluete: [],
                    script_id: '',
                    scriptDetail: {
                        cover_url: '',
                        name: '',
                        classification_name: '',
                        script_introduction: '',
                        script_content: '',
                        camera_lens: []
                    },
                    source_material: [],
                },
                //企业信息
                companyInfo: {
                    user_id: '',
                    logo: '',
                    name: '',
                    intro: '',
                    registration_number: '',
                    license_address: '',
                    business_scope: '',
                    company_address: '',
                    phone: '',
                    email: '',
                    shop_name: '',
                    shop_url: '',
                    enterprise_qualification: [],
                    registration_date: '',
                    taskCount: '',
                    goodsCount: '',
                },
                //剩余时间
                theLastTime: '',
                //报名平台
                platformValue: '',
                platformList: [],
                //平台下的账号
                accountRadio: '',
                accountList: '',
                //提交内容
                submitTextarea: '',
                //学生已提交的附件列表
                fileList: [],//本地上传的
                urlFileList: [],//链接上传的
                scriptFileList: [],//脚本库上传的
                graphicFileList: [],//图文库上传的
                videoFileList: [],//剪辑库上传的
                dataAnalysisFileList: [],//数据分析上传的
                scriptLibrarys: [],
                videoLibrarys: [],
                dataAnalysisLibrarys: [],
                graphicLibrarys: [],
                linkLibrarys: [],
                acceptFormat: '',
                //是否显示上传附件弹窗
                uploadAnnexDialog: false,
                //脚本列表
                scriptList: [],
                //视频列表
                videoLists: [],
                //图文列表
                graphicList: [],
                //数据分析列表
                dataAnalysisList: [],
                annexTab: 'scriptLibrary',
                //已选的脚本集合
                selectRowList: [],
                //已选的视频集合
                checkVideoList: [],
                //已选的脚本集合
                selectDataAnalysisList: [],
                //已选的图文集合
                selectGraphicList: [],
                linkInput1: '',
                linkInput2: '',
                linkInput3: '',
                libraryList: [],
                //图文库--百家号账号
                graphicAccountList: [],
                graphicAccountValue: '',
                graphicAccountInfo: {
                    app_id: '',
                    app_token: ''
                },
                //是否显示查看资质图片弹窗
                enterpriseQualificationDialog: false,
                big_img: '',
                //是否显示查看附件详情弹窗
                dialogViewScript: false,
                //报名是否通过企业审核
                isEnroll: null,
                task_enroll: [],
                //刷新标识
                isReloadData: true,
                //班级查询
                selectClassValue: '',
                //班级列表
                executeClass:[],
                //详情列表
                detailList:[],
                selectDetailList:[],
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //教师id
                teacher_id: null
            }
        },
        mounted() {
            /**这个接口太慢了
             * 别的接口请求完后会结束axios封装的loading状态
             * 所以后面的请求都放在了这个接口请求完后
             */
            this.getTeacherTrain();
        },
        methods: {
            ...mapMutations(['setLibrary', 'setLocalAnnexList','setteacherRemarkObj', 'setStudentContent']),
            //倒计时
            timeFormat(param) {
                return param < 10 ? '0' + param : param;
            },
            //任务详情（实训任务和脚本任务）
            getTeacherTrain() {
                this.$httpStudent.axiosGetBy(this.$api.taskDetail, {task_id: this.$route.query.id}, (res) => {
                    if (res.code === 200) {
                        this.getTaskDetail();
                        this.getPlatformList();
                        let user_account_id = this.$route.query.user_id;
                        if(user_account_id){
                            this.getAcceptStatus(user_account_id)
                        }

                        this.task_type = res.info.task_type;
                        this.scriptDetail.class_info = res.info.class_info;
                        this.scriptDetail.task_content = res.info.task_content;
                        this.scriptDetail.task_title = res.info.task_title;
                        this.scriptDetail.create_time = res.info.create_time;
                        if (res.info.script) {
                            if (res.info.script.script_content) {
                                this.scriptDetail.script.script_content = res.info.script.script_content;
                            } else {
                                this.scriptDetail.script.script_content = '暂无内容';
                            }
                            if (res.info.script.camera_lens.length > 0) {
                                this.scriptDetail.script.camera_lens = res.info.script.camera_lens;
                            }
                        }
                        this.trainDetail.task_type_name = res.info.task_type_name;
                        if (res.info.trainInfo) {
                            if (res.info.trainInfo.introduction) {
                                this.trainDetail.train_introduction.content = res.info.trainInfo.introduction.content;
                                this.trainDetail.train_introduction.name = res.info.trainInfo.introduction.name;
                            } else {
                                this.trainDetail.train_introduction.content = '暂无简介';
                                this.trainDetail.train_introduction.name = '实训简介';
                            }
                            if (res.info.trainInfo.target) {
                                this.trainDetail.train_target.content = res.info.trainInfo.target.content;
                                this.trainDetail.train_target.name = res.info.trainInfo.target.name;
                            } else {
                                this.trainDetail.train_target.content = '暂无目标';
                                this.trainDetail.train_target.name = '实训目标';
                            }
                            if (res.info.trainInfo.requirement) {
                                this.trainDetail.train_requirement.content = res.info.trainInfo.requirement.content;
                                this.trainDetail.train_requirement.name = res.info.trainInfo.requirement.name;
                            } else {
                                this.trainDetail.train_requirement.content = '暂无要求';
                                this.trainDetail.train_requirement.name = '实训要求';
                            }
                            this.trainDetail.train_content.content = res.info.trainInfo.content.content;
                            this.trainDetail.train_content.name = res.info.trainInfo.content.name;
                            if (res.info.trainInfo.script) {
                                this.trainDetail.scriptDetail = res.info.trainInfo.script;
                            }
                            this.trainDetail.script_id = res.info.trainInfo.script_id;
                            if (res.info.trainInfo.source_material) {
                                if (res.info.trainInfo.source_material.length > 0) {
                                    this.trainDetail.source_material = res.info.trainInfo.source_material;
                                }
                            }
                            if (res.info.trainInfo.determined_column) {
                                this.trainDetail.train_determined = res.info.trainInfo.determined_column;
                                if (res.info.trainInfo.determined_column.length > 0) {
                                    if (res.info.trainInfo.determined_column.length === 1) {
                                        this.trainDetail.train_determined_one.name = res.info.trainInfo.determined_column[0].name;
                                        this.trainDetail.train_determined_one.content = res.info.trainInfo.determined_column[0].content;
                                    }
                                    if (res.info.trainInfo.determined_column.length === 2) {
                                        this.trainDetail.train_determined_two.name = res.info.trainInfo.determined_column[1].name;
                                        this.trainDetail.train_determined_two.content = res.info.trainInfo.determined_column[1].content;
                                    }
                                }
                            }
                            this.trainDetail.train_evaluete = res.info.trainInfo.evaluate;
                        }
                    }
                })
            },
            //任务详情（普通任务）
            getTaskDetail() {
                this.$httpStudent.axiosGetBy(this.$api.studentTaskDetail, {task_id: this.$route.query.id}, (res) => {
                    if (res.code === 200) {
                        console.log(res)
                        this.taskDetailData = res.task_info;
                        this.submitTextarea = this.taskDetailData.task_works[0].task_works_content

                        this.teacher_id = res.user_info.id;
                        if (res.task_info.task_enroll.length > 0) {
                            this.task_enroll = res.task_info.task_enroll;
                            this.isEnroll = res.task_info.task_enroll[0].state;
                        }
                        //学生已上传的附件列表
                        //本地上传的文件
                        if (res.task_info.works_file && res.task_info.works_file.length > 0) {
                            res.task_info.works_file.forEach ((item) => {
                                this.fileList.push({
                                    file_path: item.file_path2,
                                    file_name: item.file_name
                                })
                            })
                        }
                        //其他库上传的文件
                        if (res.task_info.task_library && res.task_info.task_library.length > 0) {
                            res.task_info.task_library.forEach ((item) => {
                                if (item.type === 1) {
                                    this.scriptFileList.push({
                                        file_name: item.content.name,
                                        id: item.content.id
                                    })
                                    this.selectRowList.push({
                                        camera_lens: item.content.camera_lens,
                                        classification_id: item.content.classification_id,
                                        classification_name: item.content.classification_name,
                                        cover_url: item.content.cover_url,
                                        create: item.content.create,
                                        name: item.content.name,
                                        id: item.content.id,
                                        script_content: item.content.script_content,
                                        script_introduction: item.content.script_introduction,
                                        user_id: item.content.user_id,
                                    })
                                    this.scriptLibrarys.push({
                                        id: item.content.id,
                                        'type': 'script',
                                        name: item.content.name
                                    })
                                }
                                if (item.type === 2) {
                                    // this.selectGraphicList.push({
                                    //     name: item.content.name,
                                    //     url: item.content.url,
                                    // })
                                    this.graphicLibrarys.push({
                                        name: item.content.name,
                                        url: item.content.url,
                                        type: "txt"
                                    })
                                    this.graphicFileList.push({
                                        file_name: item.content.name,
                                        url: item.content.url
                                    })
                                }
                                if (item.type === 3) {
                                    this.checkVideoList.push(item.content.id);
                                    this.videoFileList.push({
                                        file_name: item.content.title,
                                        id: item.content.id
                                    })
                                    this.videoLibrarys.push({
                                        type: 'video',
                                        id: item.content.id,
                                        name: item.content.title
                                    })
                                }
                                if (item.type === 4) {
                                    this.urlFileList.push({
                                        type: 'url',
                                        file_name: item.content.url,
                                        id: item.content.id
                                    })
                                    if (this.urlFileList.length > 0) {
                                        for (let i = 0; i < this.urlFileList.length; i++) {
                                            if (this.urlFileList[i].id === 1) {
                                                this.linkInput1 = '';
                                                this.linkInput1 = this.urlFileList[i].file_name;
                                            }
                                            if (this.urlFileList[i].id === 2) {
                                                this.linkInput2 = '';
                                                this.linkInput2 = this.urlFileList[i].file_name;
                                            }
                                            if (this.urlFileList[i].id === 3) {
                                                this.linkInput3 = '';
                                                this.linkInput3 = this.urlFileList[i].file_name;
                                            }
                                            let libraryObj = {}
                                            libraryObj.type = 'url';
                                            libraryObj.url = item.content.url;
                                            libraryObj.id = item.content.id;
                                            this.linkLibrarys.push(libraryObj);
                                        }
                                        let urlTemp = [];
                                        let stringify = {};
                                        for (let i = 0; i < this.linkLibrarys.length; i++) {
                                            let keys = Object.keys(this.linkLibrarys[i]);
                                            keys.sort(function(a, b) {
                                                return (Number(a) - Number(b));
                                            });
                                            let str = '';
                                            for (let j = 0; j < keys.length; j++) {
                                                str += JSON.stringify(keys[j]);
                                                str += JSON.stringify(this.linkLibrarys[i][keys[j]]);
                                            }
                                            if (!stringify.hasOwnProperty(str)) {
                                                urlTemp.push(this.linkLibrarys[i]);
                                                stringify[str] = true;
                                            }
                                        }
                                        this.linkLibrarys = urlTemp;
                                    }
                                }
                                if (item.type === 5) {
                                    this.dataAnalysisFileList.push({
                                        file_name: item.content.title,
                                        id: item.content.id
                                    })
                                    this.selectDataAnalysisList.push({
                                        create_time: item.content.create_time,
                                        title: item.content.title,
                                        id: item.content.id,
                                    })
                                    this.dataAnalysisLibrarys.push({
                                        type: 'dataAnalysis',
                                        title: item.content.title,
                                        id: item.content.id,
                                    })
                                }
                            })
                        }
                        if (res.user_info) {
                            this.companyInfo.user_id = res.user_info.id;
                            this.getCompanyInfo();
                        }

                        let leftTime = res.task_info.task_end_time;
                        var interval = setInterval(() => {
                            leftTime -= 1;
                            let obj = null;
                            // 如果活动未结束，对时间进行处理
                            if (leftTime > 0) {
                                let time = leftTime;
                                // 获取天、时、分、秒
                                let day = parseInt(time / (60 * 60 * 24));
                                let hou = parseInt(time % (60 * 60 * 24) / 3600);
                                let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                                let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                                obj = {
                                    day: this.timeFormat(day),
                                    hou: this.timeFormat(hou),
                                    min: this.timeFormat(min),
                                    sec: this.timeFormat(sec)
                                };
                            } else { // 活动已结束，全部设置为'00'
                                obj = {
                                    day: '00',
                                    hou: '00',
                                    min: '00',
                                    sec: '00'
                                };
                                clearInterval(interval);
                                this.getClassList();
                            }
                            this.theLastTime = obj.day + '天' + obj.hou + '时' + obj.min + '分' + obj.sec + '秒';
                        }, 1000);
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取企业信息
            getCompanyInfo() {
                let param = {
                    user_id: this.companyInfo.user_id,
                    statistics: 'show'
                }
                this.$httpStudent.axiosGetBy(this.$api.enterprise_getEnterpriseDetail, param, (res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            if (res.data.company_profile.length !== 0) {
                                this.companyInfo.name = res.data.company_profile.name;
                                this.companyInfo.logo = res.data.company_profile.logo.scr;
                                this.companyInfo.intro = res.data.company_profile.intro;
                                this.companyInfo.business_scope = res.data.company_profile.business_scope;
                                this.companyInfo.company_address = res.data.company_profile.company_address;
                                this.companyInfo.phone = res.data.company_profile.phone;
                                this.companyInfo.email = res.data.company_profile.email;
                                this.companyInfo.registration_date = res.data.company_profile.registration_date;
                                this.companyInfo.shop_name = res.data.company_profile.shop_name;
                                this.companyInfo.shop_url = res.data.company_profile.shop_url;
                            } else {

                            }
                            this.companyInfo.taskCount = res.data.enterpriseNum.taskCount;
                            this.companyInfo.goodsCount = res.data.enterpriseNum.goodsCount;
                            if (res.data.company_profile.enterprise_qualification) {
                                if (res.data.company_profile.enterprise_qualification.length > 0) {
                                    this.companyInfo.enterprise_qualification = res.data.company_profile.enterprise_qualification;
                                    this.big_img = res.data.company_profile.enterprise_qualification[0].src;
                                }
                            }
                        }
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //下载附件
            downLoadAnnex(item) {
                window.location.href = item.file_url;
            },
            //报名弹窗
            goToApply() {
                this.applyDialog = !this.applyDialog;
                this.getPlatformList();
            },
            getAcceptStatus(val){
                let param = {
                    task_id:this.$route.query.id,
                    user_account_id:val,
                }
                this.$httpStudent.axiosGetBy(this.$api.intelligent_showAccept,param,res=>{
                    if(res.code == 200){
                        if(res.data.state !== 0 ){
                            this.isApply = false;
                            this.apply_status = res.data.state;
                            this.applyStatus = res.data.state;
                            this.getTaskDetail();
                        }
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            },
            //报名
            applyEnroll(val) {
                let user_account_id = this.$route.query.user_id;
                let formData = new FormData();
                formData.append('task_id', this.$route.query.id);
                formData.append('account_id', user_account_id);
                formData.append('enroll_state', val);
                this.$httpStudent.axiosPost(this.$api.taskEnroll, formData, (res) => {
                    if (res.code === 200) {
                        this.agreeApply(val);
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            agreeApply(val){
                this.isApply = false;
                this.apply_status = val;
                let user_account_id = this.$route.query.user_id;
                let param = {
                    task_id:this.$route.query.id,
                    user_account_id:user_account_id,
                    state:val
                }
                this.$httpStudent.axiosPost(this.$api.intelligent_acceptTask,param,res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        this.getAcceptStatus(user_account_id)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            },
            //获取平台列表
            getPlatformList() {
                // let params = {};
                this.$httpStudent.axiosGet(this.$api.account_accountPlatform, (res) => {
                    if (res.code === 200) {
                        this.platformList = res.data;
                    } else {
                        this.$message.warning(res.message);
                    }

                }, (err) => {
                    console.log(err);
                });
            },
            //选择平台
            selectPlatform() {
                this.getPlatformAccountList()
            },
            //获取平台下的账号列表
            getPlatformAccountList() {
                let param = {
                    platform_type: this.platformValue
                }
                this.$httpStudent.axiosGetBy(this.$api.get_account, param, (res) => {
                    if (res.code === 200) {
                        this.accountList = res.data.data;
                    } else {
                        this.$message.warning(res.message);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //报名
            applyClick() {
                let formData = new FormData();
                formData.append('task_id', this.$route.query.id);
                formData.append('account_id', this.accountRadio);
                this.$httpStudent.axiosPost(this.$api.taskEnroll, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.applyDialog = false;
                        this.getTaskDetail();
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            //上传附件
            uploadAnnex() {
                this.uploadAnnexDialog = true;
                //脚本
                if (this.scriptFileList.length > 0) {
                    this.getScriptList(this.scriptFileList);
                } else {
                    this.scriptLibrarys = [];
                    this.getScriptList(this.scriptLibrarys);
                }
                //图文
                if (this.graphicFileList.length > 0) {
                    this.selectGraphicList = [];
                    this.getUserInfo(this.graphicFileList);
                    this.graphicLibrarys = [];
                    this.graphicFileList.forEach(item => {
                        this.graphicLibrarys.push({
                            'type': 'txt',
                            'url': item.url,
                            'name': item.file_name
                        })
                    })
                }
                // else {
                //     this.scriptLibrarys = [];
                //     this.getScriptList(this.scriptLibrarys);
                // }
                //视频
                this.getVideoList();
                //数据分析
                if (this.dataAnalysisFileList.length > 0) {
                    this.getDataAnalysisList(this.dataAnalysisFileList);
                } else {
                    this.dataAnalysisLibrarys = [];
                    this.getDataAnalysisList(this.dataAnalysisLibrarys);
                }

                //链接
                if (this.urlFileList.length > 0) {
                    this.linkInput1 = '';
                    this.linkInput2 = '';
                    this.linkInput3 = '';
                    for (let i = 0; i < this.urlFileList.length; i++) {
                        if (this.urlFileList[i].id === 1) {
                            this.linkInput1 = this.urlFileList[i].file_name;
                        }
                        if (this.urlFileList[i].id === 2) {
                            this.linkInput2 = this.urlFileList[i].file_name;
                        }
                        if (this.urlFileList[i].id === 3) {
                            this.linkInput3 = this.urlFileList[i].file_name;
                        }
                    }
                } else {
                    this.linkInput1 = '';
                    this.linkInput2 = '';
                    this.linkInput3 = '';
                }
            },
            chooseFile(event) {
                let fileList = event.target.files;
                for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].size < 1024 * 1024 * 500) {
                        let formData = new FormData();
                        formData.append('file', fileList[i]);
                        this.$httpStudent.axiosPost(this.$api.studentUploadFile, formData, (res) => {
                            if (res.code === 200) {
                                this.fileList.push({
                                    file_path: res.url,
                                    file_name: res.name
                                })
                                this.$message.success(res.msg);
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        this.$message.warning(fileList[i].name + '文件超过500M')
                    }
                }
                event.target.value = '';
            },
            //选择的脚本
            chooseScript(val) {
                if (val.length > 0) {
                    this.scriptLibrarys = [];
                    this.selectRowList = val;
                    let arr = this.selectRowList;
                    for(let i = 0; i < arr.length; i++) {
                        let obj = arr[i];
                        this.scriptLibrarys.push({
                            'id': obj.id,
                            'type': 'script',
                            'name': obj.name
                        })
                    }
                } else {
                    this.scriptLibrarys = [];
                }
            },
            //选择的视频
            chooseUploadVideo(val) {
                if (this.checkVideoList.length > 0) {
                    this.videoLibrarys = [];
                    for (let i = 0; i < this.checkVideoList.length; i++) {
                        let libraryObj = {}
                        libraryObj.type = 'video';
                        libraryObj.id = this.checkVideoList[i];
                        for (let j = 0; j < this.videoLists.length; j++) {
                            if (this.checkVideoList[i] === this.videoLists[j].id) {
                                libraryObj.name = this.videoLists[j].title;
                            }
                        }
                        this.videoLibrarys.push(libraryObj);
                    }
                } else {
                    this.videoLibrarys = [];
                }
            },
            //选择的数据分析
            chooseDataAnalysis(val) {
                if (val.length > 0) {
                    this.dataAnalysisLibrarys = [];
                    this.selectDataAnalysisList = val;
                    let arr = this.selectDataAnalysisList;
                    for(let i = 0; i < arr.length; i++) {
                        let obj = arr[i];
                        this.dataAnalysisLibrarys.push({
                            'id': obj.id,
                            'type': 'dataAnalysis',
                            'name': obj.title
                        })
                    }
                } else {
                    this.dataAnalysisLibrarys = [];
                }
            },
            //选择的图文
            chooseGraphic(val) {
                let one_time = val;
                if (val.length > 0) {
                    this.graphicLibrarys = [];
                    val.forEach(item => {
                        this.selectGraphicList.push({
                            name: item.title,
                            url: item.article_url
                        })
                        one_time.push({
                            name: item.title,
                            url: item.article_url
                        })
                    })
                    let graphicTemp = [];
                    let stringify = {};
                    for (let i = 0; i < this.selectGraphicList.length; i++) {
                        let keys = Object.keys(this.selectGraphicList[i]);
                        keys.sort(function(a, b) {
                            return (Number(a) - Number(b));
                        });
                        let str = '';
                        for (let j = 0; j < keys.length; j++) {
                            str += JSON.stringify(keys[j]);
                            str += JSON.stringify(this.selectGraphicList[i][keys[j]]);
                        }
                        if (!stringify.hasOwnProperty(str)) {
                            graphicTemp.push(this.selectGraphicList[i]);
                            stringify[str] = true;
                        }
                    }
                    this.selectGraphicList = graphicTemp;
                    let arr = this.selectGraphicList;
                    for(let i = 0; i < arr.length; i++) {
                        let obj = arr[i];
                        this.graphicLibrarys.push({
                            'type': 'txt',
                            'url': obj.url,
                            'name': obj.name
                        })
                    }
                } else {
                    this.graphicLibrarys = [];
                }
            },
            //附件tab切换
            changeAnnexTab(tab) {
                if (this.graphicList.length === 0 ) {
                    if (tab.label === '图文库') {
                        this.getUserInfo();
                    }
                }
            },
            //保存已选的附件
            saveAnnex() {
                this.uploadAnnexDialog = false;
                //脚本库
                if (this.scriptLibrarys.length > 0) {
                    for(let i = 0; i < this.scriptLibrarys.length; i++){
                        let flag = true;
                        for (let j = 0; j < this.scriptFileList.length; j++) {
                            if (this.scriptLibrarys[i].name === this.scriptFileList[j].file_name) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.scriptFileList.push({
                                file_name: this.scriptLibrarys[i].name,
                                id: this.scriptLibrarys[i].id
                            })
                        }
                    }
                    this.scriptFileList = [];
                    this.scriptLibrarys.forEach(item => {
                        this.scriptFileList.push({
                            file_name: item.name,
                            id: item.id
                        })
                    })
                    this.reload();
                } else {
                    this.scriptFileList = [];
                }
                //图文库
                if (this.graphicLibrarys.length > 0) {
                    // this.graphicFileList = [];
                    this.graphicLibrarys.forEach(item => {
                        this.graphicFileList.push({
                            file_name: item.name,
                            url: item.url
                        })
                    })

                    let graphicTemp = [];
                    let stringify = {};
                    for (let i = 0; i < this.graphicFileList.length; i++) {
                        let keys = Object.keys(this.graphicFileList[i]);
                        keys.sort(function(a, b) {
                            return (Number(a) - Number(b));
                        });
                        let str = '';
                        for (let j = 0; j < keys.length; j++) {
                            str += JSON.stringify(keys[j]);
                            str += JSON.stringify(this.graphicFileList[i][keys[j]]);
                        }
                        if (!stringify.hasOwnProperty(str)) {
                            graphicTemp.push(this.graphicFileList[i]);
                            stringify[str] = true;
                        }
                    }
                    this.graphicFileList = graphicTemp;

                    if (this.graphicFileList.length > 0) {
                        this.graphicLibrarys = [];
                        this.graphicFileList.forEach(item => {
                            this.graphicLibrarys.push({
                                name: item.file_name,
                                url: item.url,
                                type: 'txt'
                            })
                        })
                    }
                    this.reload();
                } else {
                    this.graphicFileList = [];
                }
                //剪辑库
                if (this.videoLibrarys.length > 0) {
                    for(let i = 0; i < this.videoLibrarys.length; i++){
                        let flag = true;
                        for (let j = 0; j < this.videoFileList.length; j++) {
                            if (this.videoLibrarys[i].name === this.videoFileList[j].file_name) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.videoFileList.push({
                                file_name: this.videoLibrarys[i].name,
                                id: this.videoLibrarys[i].id
                            })
                        }
                    }
                    this.videoFileList = [];
                    this.videoLibrarys.forEach(item => {
                        this.videoFileList.push({
                            file_name: item.name,
                            id: item.id
                        })
                    })
                    this.reload();
                } else {
                    this.videoFileList = [];
                }
                //数据分析
                if (this.dataAnalysisLibrarys.length > 0) {
                    for(let i = 0; i < this.dataAnalysisLibrarys.length; i++){
                        let flag = true;
                        for (let j = 0; j < this.dataAnalysisFileList.length; j++) {
                            if (this.dataAnalysisLibrarys[i].name === this.dataAnalysisFileList[j].file_name) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.dataAnalysisFileList.push({
                                file_name: this.dataAnalysisLibrarys[i].name,
                                id: this.dataAnalysisLibrarys[i].id
                            })
                        }
                    }
                    this.dataAnalysisFileList = [];
                    this.dataAnalysisLibrarys.forEach(item => {
                        this.dataAnalysisFileList.push({
                            file_name: item.name,
                            id: item.id
                        })
                    })
                    this.reload();
                } else {
                    this.dataAnalysisFileList = [];
                }
                //链接
                if (this.urlFileList.length > 0) {
                    this.urlFileList.forEach(item => {
                        if (item.id === 1) {
                            item.file_name = this.linkInput1;
                            item.id = 1;
                            item.type = 'url'
                        }
                        if (item.id === 2) {
                            item.file_name = this.linkInput2;
                            item.id = 2;
                            item.type = 'url'
                        }
                        if (item.id === 3) {
                            item.file_name = this.linkInput3;
                            item.id = 3;
                            item.type = 'url'
                        }
                    })
                } else {
                    this.getInput1();
                    this.getInput2();
                    this.getInput3();
                }
                if ((this.urlFileList.length > 0 && !this.urlFileList[0])
                    || (this.urlFileList.length > 0 && this.urlFileList[2])
                    || (this.urlFileList.length > 0 && this.urlFileList[1])) {
                    this.getInput1();
                }
                if ((this.urlFileList.length > 0 && !this.urlFileList[1])
                    || (this.urlFileList.length > 0 && this.urlFileList[2])
                    || (this.urlFileList.length > 0 && this.urlFileList[0])) {
                    this.getInput2();
                }
                if ((this.urlFileList.length > 0 && !this.urlFileList[2] && !this.urlFileList[1])
                    || (this.urlFileList.length > 0 && this.urlFileList[1])
                    || (this.urlFileList.length > 0 && this.urlFileList[0])) {
                    this.getInput3();
                }
                this.linkLibrarys = [];
                this.urlFileList = [];
                if (this.linkInput1) {
                    this.linkLibrarys.push({
                        id: 1,
                        type: 'url',
                        url: this.linkInput1
                    })
                    this.urlFileList.push({
                        id: 1,
                        type: 'url',
                        file_name: this.linkInput1
                    })
                }
                if (this.linkInput2) {
                    this.linkLibrarys.push({
                        id: 2,
                        type: 'url',
                        url: this.linkInput2
                    })
                    this.urlFileList.push({
                        id: 2,
                        type: 'url',
                        file_name: this.linkInput2
                    })
                }
                if (this.linkInput3) {
                    this.linkLibrarys.push({
                        id: 3,
                        type: 'url',
                        url: this.linkInput3
                    })
                    this.urlFileList.push({
                        id: 3,
                        type: 'url',
                        file_name: this.linkInput3
                    })
                }
            },
            //push并验证链接
            getInput1() {
                if (this.linkInput1) {
                    this.urlFileList.push({
                        file_name: this.linkInput1,
                        id: 1,
                        type: 'url'
                    })
                    let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                    let reg = new RegExp(strRegex);
                    if (!reg.test(this.linkInput1)) {
                        this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                        return;
                    }
                }
            },
            getInput2() {
                if (this.linkInput2) {
                    this.urlFileList.push({
                        file_name: this.linkInput2,
                        id: 2,
                        type: 'url'
                    })
                    let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                    let reg = new RegExp(strRegex);
                    if (!reg.test(this.linkInput2)) {
                        this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                        return;
                    }
                }
            },
            getInput3 () {
                if (this.linkInput3) {
                    this.urlFileList.push({
                        file_name: this.linkInput3,
                        id: 3,
                        type: 'url'
                    })
                    let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                    let reg = new RegExp(strRegex);
                    if (!reg.test(this.linkInput3)) {
                        this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                        return;
                    }
                }
            },
            //刷新已上传的附件列表
            reload () {
                this.isReloadData = false;
                this.$nextTick(() => {
                    this.isReloadData = true;
                })
            },
            //删除附件
            delFiles(item, index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$httpStudent.axiosGetBy(this.$api.delFile, {file_url: item.file_path}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.fileList.splice(index, 1)
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //删除脚本库的附件
            delScriptFiles(index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message.success('删除成功');
                    this.scriptFileList.splice(index, 1);
                    if (this.scriptFileList.length === 0) {
                        this.scriptLibrarys = [];
                    }
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //删除图文库的附件
            delGraphicFiles(index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message.success('删除成功');
                    this.graphicFileList.splice(index, 1);
                    if (this.graphicFileList.length === 0) {
                        this.graphicLibrarys = [];
                        this.$refs.multipleGraphic.clearSelection();
                    } else {
                        this.graphicLibrarys = [];
                        this.graphicFileList.forEach(item => {
                            this.graphicLibrarys.push({
                                name: item.file_name,
                                url: item.url,
                                type: 'txt'
                            })
                        })
                    }
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //删除剪辑库的附件
            delVideoFiles(index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message.success('删除成功');
                    this.videoFileList.splice(index, 1);
                    if (this.videoFileList.length > 0) {
                        this.checkVideoList = [];
                        this.videoFileList.forEach(item => {
                            this.checkVideoList.push(item.id);
                        })
                    } else {
                        this.checkVideoList = [];
                        this.videoLibrarys = [];
                    }
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //删除数据分析的附件
            delDataAnalysisFiles(index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message.success('删除成功');
                    this.dataAnalysisFileList.splice(index, 1);
                    if (this.dataAnalysisFileList.length === 0) {
                        this.dataAnalysisLibrarys = [];
                    }
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //删除链接的附件
            delUrlFiles(index) {
                this.$confirm('是否删除该附件', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$message.success('删除成功');
                    this.urlFileList.splice(index, 1)
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //获取脚本列表
            getScriptList(rows) {
                let param = {
                    status: 1,
                    self: 1
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, (res) => {
                    if (res.code === 200) {
                        this.scriptList = res.data.data;
                        if (rows) {
                            let _this = this;
                            _this.$nextTick(function() {
                                _this.toggleSelectionScript(rows);
                            })
                        } else {
                            this.$refs.multipleScript.clearSelection();
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //默认选中脚本
            toggleSelectionScript(rows) {
                if (rows) {
                    let arr = this.$refs.multipleScript.data;
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < rows.length; j++) {
                            if (arr[i].id === rows[j].id) {
                                this.$refs.multipleScript.toggleRowSelection(this.$refs.multipleScript.data[i], true);
                            }
                        }
                    }
                } else {
                    this.$refs.multipleScript.clearSelection();
                }
            },
            //获取视频列表
            getVideoList() {
                // videoList
                let param = {
                    status: 1,
                    type: 'all'
                }
                this.$httpStudent.axiosGetBy(this.$api.videoList, param, res => {
                    if (res.code === 200) {
                        this.videoLists = res.data;
                        if (this.checkVideoList.length > 0) {
                            this.videoLibrarys = [];
                            for (let i = 0; i < this.checkVideoList.length; i++) {
                                let libraryObj = {}
                                libraryObj.type = 'video';
                                libraryObj.id = this.checkVideoList[i];
                                for (let j = 0; j < this.videoLists.length; j++) {
                                    if (this.checkVideoList[i] === this.videoLists[j].id) {
                                        libraryObj.name = this.videoLists[j].title;
                                    }
                                }
                                this.videoLibrarys.push(libraryObj);
                            }
                        } else {
                            this.videoLibrarys = [];
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            //获取数据分析列表
            getDataAnalysisList(rows) {
                let param = {
                    type: 'all',
                }
                this.$httpStudent.axiosGetBy(this.$api.analyse_analyseList, param, (res) => {
                    if (res.code === 200) {
                        this.dataAnalysisList = res.data;
                        if (rows) {
                            let _this = this;
                            _this.$nextTick(function() {
                                _this.toggleSelectionDataAnalysis(rows);
                            })
                        } else {
                            this.$refs.multipleDataAnalysis.clearSelection();
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //默认选中数据分析
            toggleSelectionDataAnalysis(rows) {
                if (rows) {
                    let arr = this.$refs.multipleDataAnalysis.data;
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < rows.length; j++) {
                            if (arr[i].id === rows[j].id) {
                                this.$refs.multipleDataAnalysis.toggleRowSelection(this.$refs.multipleDataAnalysis.data[i], true);
                            }
                        }
                    }
                } else {
                    this.$refs.multipleDataAnalysis.clearSelection();
                }
            },
            //获取文创平台百家号信息
            getUserInfo(rows) {
                this.$httpStudent.axiosGet(this.$api.api_getUserList, (res) => {
                    if (res.code === 200) {
                        if (res.data.data.length === 0) {
                            this.$message.warning('请先到账号管理中绑定百家号')
                        } else {
                            this.graphicAccountList = res.data.data;
                            if (!this.graphicAccountValue) {
                                this.graphicAccountValue = res.data.data[0].id;
                                this.graphicAccountInfo = {
                                    app_id: res.data.data[0].app_id,
                                    app_token: res.data.data[0].app_token,
                                }
                            }
                            this.getGraphicList(rows);
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //切换百家号
            toggleGraphicAccount(val) {
                this.graphicAccountValue = val;
                this.graphicAccountList.forEach((item) => {
                    if (item.id === this.graphicAccountValue) {
                        this.graphicAccountInfo = {
                            app_id: item.app_id,
                            app_token: item.app_token,
                        }
                    }
                });
                this.getGraphicList(this.graphicFileList);
            },
            //获取图文创作列表
            getGraphicList(rows) {
                let params = {
                    app_id: this.graphicAccountInfo.app_id,
                    app_token: this.graphicAccountInfo.app_token,
                    collection: 'publish'
                }
                this.$httpStudent.axiosGetBy(this.$api.api_articleListAll, params, (res) => {
                    if (res.code === 200) {
                        this.graphicList = res.data.items;
                        if (rows && rows.length > 0) {
                            this.graphicList.forEach(item => {
                                rows.forEach(selectItem => {
                                    if (item.article_url === selectItem.url || item.article_url === selectItem.article_url || item.url === selectItem.url) {
                                        let _this = this;
                                        _this.$nextTick(function() {
                                            _this.toggleSelectionGraphic(rows);
                                        })
                                    }
                                })
                            })
                        } else {
                            this.$refs.multipleGraphic.clearSelection();
                        }
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //默认选中图文
            toggleSelectionGraphic(rows) {
                if (rows) {
                    let arr = this.$refs.multipleGraphic.data;
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < rows.length; j++) {
                            if (arr[i].article_url === rows[j].url || arr[i].article_url === rows[j].article_url) {
                                this.$refs.multipleGraphic.toggleRowSelection(this.$refs.multipleGraphic.data[i], true);
                            }
                        }
                    }
                } else {
                    this.$refs.multipleGraphic.clearSelection();
                }
            },
            //提交作品
            taskSubmit() {
                let formData = new FormData();
                if (this.fileList.length > 0) {
                    formData.append('file', JSON.stringify(this.fileList));
                }
                formData.append('content', this.submitTextarea);
                formData.append('task_id', this.$route.query.id);

                let sumArr = [];
                if (this.scriptLibrarys.length > 0) {
                    sumArr = [];
                    sumArr = sumArr.concat(this.scriptLibrarys).concat(this.videoLibrarys).concat(this.graphicLibrarys).concat(this.dataAnalysisLibrarys).concat(this.linkLibrarys);
                }
                if (this.videoLibrarys.length > 0) {
                    sumArr = [];
                    sumArr = sumArr.concat(this.scriptLibrarys).concat(this.videoLibrarys).concat(this.graphicLibrarys).concat(this.dataAnalysisLibrarys).concat(this.linkLibrarys);
                }
                if (this.graphicLibrarys.length > 0) {
                    sumArr = [];
                    sumArr = sumArr.concat(this.scriptLibrarys).concat(this.videoLibrarys).concat(this.graphicLibrarys).concat(this.dataAnalysisLibrarys).concat(this.linkLibrarys);
                }
                if (this.dataAnalysisLibrarys.length > 0) {
                    sumArr = [];
                    sumArr = sumArr.concat(this.scriptLibrarys).concat(this.videoLibrarys).concat(this.graphicLibrarys).concat(this.dataAnalysisLibrarys).concat(this.linkLibrarys);
                }
                if (this.linkLibrarys.length > 0) {
                    sumArr = [];
                    sumArr = sumArr.concat(this.scriptLibrarys).concat(this.videoLibrarys).concat(this.graphicLibrarys).concat(this.dataAnalysisLibrarys).concat(this.linkLibrarys);
                }
                if (sumArr.length > 0) {
                    formData.append('library', JSON.stringify(sumArr));
                }
                if (this.fileList.length === 0 && sumArr.length === 0) {
                    return this.$message.error('还未选择文件！');
                }
                this.$httpStudent.axiosPost(this.$api.submitWorks, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.addUserScore();
                        this.$router.push({
                            path: '/student/task/index'
                        })
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            addUserScore() {
                if (this.platformValue === '') {
                    return
                }
                let param = {
                    user_account_id: this.accountRadio,
                    type: this.platformValue,
                    identifying: this.platformValue,
                    score_type: 3
                }
                this.$httpStudent.axiosGetBy(this.$api.intelligent_addUserScore, param, res => {
                    if (res.code == 200) {
                        console.log(res)
                    }
                })
            },
            //查看企业用户首页
            viewCompanyDetail(id) {
                this.$router.push({
                    path: '/student/task/companyUserIndex',
                    query: {
                        c_id: id
                    }
                })
            },
            //
            viewEnterpriseQualification() {
                this.enterpriseQualificationDialog = true;
            },
            //
            viewBigImg(src) {
                this.big_img = src;
            },
            //查看脚本详情
            viewScriptDetail() {
                this.dialogViewScript = true;

            },
            //下载附件
            downLoadTrainAnnex(item) {
                window.location.href = item.url;
            },
            //返回
            goBack() {
                this.$router.go(-1);
            },
            //班级筛选
            classSelect(val){
                this.selectClassValue = val;
                this.getDetailList()
            },
            //获取班级列表
            getClassList(){
                let param = {
                    teacher_id: this.teacher_id
                }
                this.$httpStudent.axiosGetBy(this.$api.classList, param, (res)=>{
                    if(res.code === 200){
                        this.executeClass = res.list;
                        if (res.list && res.list.length > 0) {
                            this.selectClassValue = res.list[0].id;
                            this.getDetailList();
                        }
                    } else {
                        this.$message.warning('班级列表获取失败！')
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            // 获取详情列表
            getDetailList(){
                const id = this.$route.query.id;
                let param = {
                    task_id:id
                };
                if(this.value){
                    param.class_id = this.value
                }
                this.$httpStudent.axiosGetBy(this.$api.worksList,param,(res)=>{
                    if(res.code === 200){
                        this.detailList = res.list;
                        this.selectDetailList = res.list;
                        this.studentPages.total = res.total;
                    }
                })
            },
            //分页
            studentCurrentChange(val){
                this.studentPages.currentPageNum = val;
                this.getDetailList();
            },
            //查看提交详情
            viewAnnex(row) {
                this.setLibrary(row.task_works_library);
                this.setLocalAnnexList(row.task_works_file);
                this.setteacherRemarkObj(row.teacher_comment);
                this.setStudentContent(row.task_works_content);
                if (row.teacher_comment) {
                    this.$router.push({
                        path: '/student/task/annexDetails',
                        query: {
                            sName: row.username,//学生姓名
                            cName: row.name,//班级名称
                            tState: row.teacher_comment.is_qualified,//任务状态：合格/不合格/暂未操作
                            taskType:this.taskType, //任务类型 1普通 3实训 4脚本
                            taskId:this.$route.query.id,
                            userId: row.user_id,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/task/annexDetails',
                        query: {
                            sName: row.username,//学生姓名
                            cName: row.name,//班级名称
                            tState: '0',//任务状态：合格/不合格/暂未操作
                            taskType:this.taskType, //任务类型 1普通 3实训 4脚本
                            taskId:this.$route.query.id,
                            userId: row.user_id,
                        }
                    })
                }

            },
        }
    }
</script>

<style scoped lang="scss">
    .task-detail-content {
        display: flex;
        justify-content: start;
        width: 100%;
        min-width: 1000px;
        height: 100%;
        min-height: calc(100vh - 110px);
        margin-bottom: 10px;
        box-sizing: border-box;
        /*::v-deep .el-scrollbar {*/
            /*min-height: calc(100vh - 110px);*/
            /*background-color: #fff;*/
            /*::v-deep .el-scrollbar__wrap {*/
                /*overflow-x: hidden;*/
            /*}*/
        /*}*/
        .task-detail {
            flex: 1;
            width: calc(100% - 385px);
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .company-info {
            width: 360px;
            height: 100%;
            min-height: calc(100vh - 110px);
            background: #fff;
            margin-left: 25px;
            padding: 30px;
            box-sizing: border-box;
            .company-header {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                cursor: pointer;
                .company-cover {
                    width: 174px;
                    height: 174px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .company-name {
                    font-size: 18px;
                    font-weight: 500;
                    border-bottom:1px solid transparent;
                    &:hover {
                        border-bottom:1px solid #2338E6;
                        color: #2338E6;
                    }
                }
            }
            .company-detail {
                margin-top: 20px;
                .detail-item {
                    display: flex;
                    margin-bottom: 25px;
                    .title {
                        min-width: 70px;
                        margin-right: 5px;
                    }
                    a {
                        color: #584EEE;
                    }
                    .determine-btn {
                        background-color: #584EEE;
                        border-color: #584EEE;
                        padding: 7px 14px;
                    }
                }
            }
            .task-goods {
                display: flex;
                justify-content: center;
                .publish-num {
                    margin-right: 32px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 20px;
                    .num {
                        color: #9ACC5B;
                        font-size: 24px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .task-content {
        padding: 20px;
        background-color: #fff;
        .task-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: #333;
            font-weight: 500;
            height: 50px;
            border-bottom: 1px solid #E5E5E5;
            padding: 0 15px;
            .text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                margin-right: 10px;
            }
            .bounty {
                display: block;
                width: 80px;
                color: #FF0000;
            }
        }
        .task-info {
            display: flex;
            justify-content: space-between;
            padding: 20px 15px;
            border-bottom: 1px solid #E5E5E5;
            ul {
                font-size: 14px;
                color: #666666;
                li {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .task-status {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: space-around;
                .account-btn {
                    font-size: 16px;
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                }
                .new-account-btn {
                    font-size: 16px;
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    margin-left: 10px;
                }
                .new-disAccount-btn {
                    font-size: 16px;
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    margin-left: 10px;
                    background: #FF0000;
                }
                .time-out {
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 16px;
                    background-color: #DCDCDC;
                    text-align: center;
                    border-radius: 2px;
                }
                .time-ing {
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 16px;
                    background-color: #9ACC5B;
                    color: #fff;
                    text-align: center;
                    border-radius: 2px;
                }
                img {
                    width: 80px;
                    height: 29px;
                }
            }
        }
        .task-require {
            padding: 20px 15px;
            border-bottom: 1px solid #E5E5E5;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #00B47F;
                    margin-right: 6px;
                }
            }
            .require-content {
                color: #666;
                font-size: 14px;
            }
        }
        .task-goods {
            padding: 20px 15px;
            border-bottom: 1px solid #E5E5E5;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #00B47F;
                    margin-right: 6px;
                }
            }
            .goods-content {
                display: flex;
                align-items: center;
                .goods-cover {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    img {
                        max-width: 80px;
                        max-height: 80px;
                    }
                }
                .goods-info {
                    display: flex;
                    flex-direction: column;
                    .goods-title {
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .price {
                        color: #FF0000;
                        font-weight: 500;
                    }
                }
            }
        }
        .task-annex {
            padding: 20px 15px 0;
            border-bottom: 1px solid #E5E5E5;
            .title {
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: #00B47F;
                    margin-right: 6px;
                }
            }
            .annex-content {
                display: flex;
                justify-content: start;
                flex-wrap: wrap;
                li {
                    margin-bottom: 15px;
                    width: 50%;
                    .file-name {
                        float: left;
                        display: block;
                        max-width: 300px;
                        margin-right: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                        border-bottom:1px solid transparent;
                        i {
                            color: #409EFF;
                        }
                    }
                    .down-progress {
                        display: flex;
                        align-items: center;
                        .download {
                            color: #2338E6;
                            width: 50px;
                            margin-right: 20px;
                            cursor: pointer;
                            border-bottom:1px solid transparent;
                            .download-bg {
                                display: block;
                                width: 20px;
                                height: 20px;
                                background-color: #409EFF;
                                color: #fff;
                                border-radius: 4px;
                                line-height: 20px;
                                text-align: center;
                                i {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .script-name {
                    cursor: pointer;
                    border-bottom:1px solid transparent;
                    &:hover {
                        border-bottom:1px solid #2338E6;
                        color: #2338E6;
                    }
                }
            }
        }
    }
    .task-submit {
        padding: 20px 30px;
        margin: 20px 0;
        background-color: #fff;
        .title {
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 3px;
                height: 10px;
                background-color: #00B47F;
                margin-right: 6px;
            }
        }
        .file-list {
            display: flex;
            flex-wrap: wrap;
            margin: 15px 0;
            li {
                width: 205px;
                height: 40px;
                background-color: #EEEEEE;
                color: #666666;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
                margin: 10px 10px 10px 0;
                box-sizing: border-box;
                .file-type {
                    display: flex;
                    align-items: center;
                    .file-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                        width: 120px;
                    }
                    i {
                        color: #BFBFBF;
                        font-size: 24px;
                        margin-right: 10px;
                    }
                }
                .file-text {
                    display: flex;
                    align-items: center;
                    i {
                        font-size: 12px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .el-textarea {
            margin-bottom: 15px;
        }
        .account-btn {
            display: flex;
            margin-top: 15px;
        }
    }
    .task-submit-list {
        display: flex;
        width: 100%;
        margin-top: 20px;
        ::v-deep .el-tab-pane {
            height: 100%;
        }
        ::v-deep .el-tabs {
            flex: 1;
            width: 1%;
        }
        ::v-deep.el-tabs{
            height: 100%;
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
        }
        ::v-deep.el-tabs__header{
            border-bottom: none;
            margin: 0;
        }
        ::v-deep.el-tabs__content{
            height: 1%;
            flex: 1;
            background: #fff;
            padding-top: 20px;
            margin-bottom: 10px;
        }
        ::v-deep.el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }
        ::v-deep.el-tabs__item{
            height: 52px;
            line-height: 52px;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
            padding: 0 30px;
            border-left: none;
        }
        .submissions{
            width: 100%;
            background: #ffffff;
            overflow: hidden;
            .submissions-title{
                margin: 24px;
                color: #333333;
                font-size: 16px;
                font-weight:400;
            }
            .class-select{
                font-size: 14px;
                span{
                    display: inline-block;
                    color: #333333;
                    margin-left: 24px;
                    margin-bottom: 30px;
                }
                ::v-deep .el-select{
                    height: 28px;
                    margin-left: 10px;
                    width: 135px;
                }

            }
            .tableList{
                span{
                    color: #666666;
                    font-size:14px;
                    font-weight: 400;
                }
            }
        }
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .apply-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #EAEAEA;
        }
        .el-select {
            margin-left: 15px;
        }
        .account-list {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            .el-radio {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                ::v-deep .el-radio__label {
                    display: flex;
                    align-items: center;
                }
            }
            .avatar-img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .no-data {
                display: block;
                text-align: center;
            }
        }
    }
    .dialog-footer {
        .el-button {
            padding: 8px 34px;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
    .annex-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #EAEAEA;
        }
        ::v-deep .el-dialog__body {
            padding: 0 20px;
        }
        .annex-list {
            height: 330px;
            ::v-deep .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #EEEEEE;
            }
            ::v-deep .el-tabs__item {
                height: 45px;
                line-height: 45px;
                font-size: 14px;
                font-weight: 400;
                border: none;
                color: #5F5C74;
            }
            ::v-deep .el-tabs__item.is-active {
                color: #16113F;
            }

            ::v-deep .el-tabs__active-bar {
                background: #9ACC5B;
            }

            ::v-deep .el-tabs__active-bar:before {
                position: absolute;
                top: -13px;
                left: 40%;
                content: '';
                width: 0;
                height: 0;
                border-style: dashed dashed solid dashed;
                border-width: 7px;
                border-color: transparent transparent #9ACC5B transparent;
            }
            .annex-tab {
                height: 100%;
                ::v-deep .el-tabs__content {
                    height: calc(100% - 60px);
                    ::v-deep .el-tab-pane {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }
                }
            }
        }
    }
    .upload-file-list {
        height: calc(100vh - 640px);
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .file-item {
            display: flex;
            justify-content: space-between;
            padding: 20px 0 10px;
            .file-type {
                i {
                    margin-right: 10px;
                }
            }
            .file-text {
                margin-right: 10px;
                span {
                    margin-right: 10px;
                }
                i {
                    font-size: 14px;
                    cursor: pointer;
                    &:hover {
                        color: #1b2dbf;
                    }
                }
            }
        }
    }
    .link-list {
        .link-item {
            display: flex;
            align-items: center;
            margin: 20px 40px 20px 0;
            .link-text {
                display: block;
                width: 85px;
                text-align: right;
                margin-right: 15px;
            }
        }
    }
    .clip-library {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .item-list {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            .clip-checkbox {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                ::v-deep .el-checkbox__input {
                    position: absolute;
                    bottom: 0;
                }
                ::v-deep .el-checkbox__label {
                    display: flex;
                    flex-direction: column;
                    height: 120px;
                    position: relative;
                    padding-left: 0;
                    justify-content: space-between;
                    .video-name {
                        display: block;
                        width: 130px;
                        padding-left: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .video-no-data {
            height: 100%;
            width: 100%;
            text-align: center;
            line-height: 270px;
        }
        .item {
            width: 150px;
            height: 120px;
            margin: 0 10px;
        }
    }
    .platform-select {
        width: 200px;
    }
    .img-dialog {
        ::v-deep .el-dialog {
            .el-dialog__header {
                border-bottom: 1px solid #EAEAEA;
            }
            .el-dialog__body {
                height: 550px;
                padding: 0;
                .img-content {
                    display: flex;
                    height: 100%;
                    .big-img {
                        flex: 1;
                        width: 1%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        /*max-height: 524px;*/
                        .show-img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 524px;
                            height: 524px;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                    .small-img {
                        display: flex;
                        flex-wrap: wrap;
                        width: 336px;
                        border-left: 1px solid #EEEEEE;
                        .el-scrollbar {
                            height: 100%;
                            width: 100%;
                        }
                        .el-scrollbar__wrap {
                            overflow-x: hidden;
                            .el-scrollbar__view {
                                display: flex;
                                flex-wrap: wrap;
                                box-sizing: border-box;
                            }
                        }
                        .img-div {
                            display: flex;
                            justify-content: center;
                            width: 128px;
                            height: 128px;
                            padding: 20px;
                            cursor: pointer;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .script-table{
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        margin-right: 150px;
        .script-table-row{
            display: flex;
            &:nth-child(1){
                line-height: 1;
                .script-table-column{
                    padding: 18px 0;
                }
            }
            .script-table-column{
                display: flex;
                flex: 1;
                width: 1%;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                margin-right: 3px;
            }
            &:nth-child(odd){
                .script-table-column{
                    background: #D3DDFF;
                }
            }
            &:nth-child(even){
                .script-table-column{
                    background: #F0F3FF;
                }
            }
        }
    }
    ::v-deep.annex-list-dialog {
        border-radius: 6px;
        .el-dialog__header{
            display: none;
        }
        ::v-deep .el-dialog__body {
            padding: 30px;
        }
    }
    .view-script-main{
        display: flex;
        flex-direction: column;
        height: 100%;
        //::v-deep .el-scrollbar__wrap {
        //  overflow-x: hidden;
        //}
        .view-script-item-top{
            display: flex;
            //align-items: center;
            .view-script-img{
                width:390px;
                height:224px;
                margin-right:30px;
                img{
                    max-width:390px;
                    max-height:224px;
                    border-radius: 10px;
                }
            }
            .view-script-right{
                flex: 1;
                width: 1%;
                .view-script-name{
                    margin-top: 10px;
                    font-size: 18px;
                    color: #333;
                }
                .view-script-fenlei{
                    font-size: 16px;
                    color: #666;
                    margin-top: 30px;
                }
                .view-script-jianjie{
                    font-size: 16px;
                    color: #666;
                    margin-top: 8px;
                    line-height: 30px;
                }
            }
        }
        .view-script-body{
            display: flex;
            flex-direction: column;
            border: 2px solid #584EEE;
            //box-shadow: 0px 10px 0px 0px #ECEBFF;
            border-radius:20px;
            margin:48px 0 30px;
            .view-script-body-title{
                width: 160px;
                height: 32px;
                background: #584EEE;
                border-radius: 16px;
                line-height: 32px;
                text-align: center;
                color: #fff;
                margin: -12px 0 0 24px;
                position: relative;
                &:before{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    left: 20px
                }
                &:after{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    right: 20px
                }
            }
            .view-script-body-content{
                display: flex;
                min-height: 100px;
                padding-left: 22px;
            }
        }

        ::v-deep.el-table{
            .warning-row {
                background: #ECEBFF;
            }
        }
        .dialog-footer {
            text-align: center;
            .el-button {
                padding: 10px 36px;
                margin-bottom: 0;
            }
            .el-button--primary {
                background-color: #2338E6;
                border: 1px solid #2338E6;
                &:hover {
                    background-color: #1b2dbf;
                    border: 1px solid #1b2dbf;
                }
            }
        }
    }
    .script-dialog-table{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        height: 265px;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .dialog-table-row{
            display: flex;
            &:nth-child(odd){
                background: #DEDCFD;
            }
            &:nth-child(even){
                background: #F8F7FF;
            }
            &:nth-child(1){
                .dialog-table-column{
                    font-size: 14px;
                    color: #222222;
                    font-weight: 400;
                }
            }
            .dialog-table-column{
                display: flex;
                flex: 1;
                width: 1%;
                justify-content: center;
                align-items: center;
                height: 50px;
            }
        }
    }
    .train-evaluete {
        padding: 20px 15px 0;
        margin-right: 20px;
        .title {
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 3px;
                height: 10px;
                background-color: #00B47F;
                margin-right: 6px;
            }
        }
    }
    .is-qualified, .no-qualified{
        font-size: 38px;
        line-height: 1;
        &.is-qualified{
            color: #20C997;
        }
        &.no-qualified{
            color: #FF0000;
        }
    }
</style>